import { Notification } from "element-ui";
import { getToken } from "./auth.js";
import EventBus from "./EventBus.js";

EventBus.$on("sendMsg", (data) => {
    // console.log("接收到消息：");
    // console.log(data.websocketToken);

    initWebSocket(data.websocketToken);
});

// ,getFlag:
// socket && socket.close();

var socket = null; //实例对象
var lockReconnect = false; //是否真正建立连接
var timeout = 10 * 1000; //20秒一次心跳
var timeoutObj = null; //心跳倒计时
var serverTimeoutObj = null; //服务心跳倒计时
var timeoutnum = null; //断开 重连倒计时
var webSocketToken = null;

const initWebSocket = async (token) => {
    //  ws:192.168.1.20:8081/manager/ubi/ws
    if ("WebSocket" in window) {
        var path = "ubiallinone-test.sigreal.cn";
        var wsStr = "ws://";

        if (
            location.origin.indexOf("localhost") == -1 &&
            location.origin.indexOf("ubiallinone-test.sigreal.cn") == -1
        ) {
            path = location.host;
            wsStr = "wss://";
        }

        const wsUrl = wsStr + path + "/manager/ubi/ws?token=" + token;
        // console.log(wsUrl);

        socket = new WebSocket(wsUrl);
        socket.onerror = webSocketOnError;
        socket.onmessage = webSocketOnMessage;
        socket.onclose = closeWebsocket;
        socket.onopen = openWebsocket;
    } else {
        Notification.error({
            title: "错误",
            message: "您的浏览器不支持websocket，请更换Chrome或者Firefox",
        });
    }
};

//建立连接
const openWebsocket = (e) => {
    console.log("建立连接");
    webSocketToken = null;

    start();
};

const start = () => {
    // console.log("开启心跳");

    //开启心跳
    timeoutObj && clearTimeout(timeoutObj);
    serverTimeoutObj && clearTimeout(serverTimeoutObj);
    timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息
        // console.log("socket:----------");
        // console.log(socket);

        if (socket.readyState == 1) {
            //如果连接正常
            socket.send("heartbeat");
        } else {
            EventBus.$emit("sendMsg", {
                websocketToken: null,
                getFlag: true,
            });
            //否则重连
            reconnect();
        }
        // console.log("超时时间：" + timeout);

        // serverTimeoutObj = setTimeout(function() {
        //     console.log("超时关闭");
        //     //超时关闭
        //     socket.close();

        //     EventBus.$emit("sendMsg", {
        //         websocketToken: null,
        //         getFlag: true,
        //     });
        // }, timeout);
    }, timeout);
};

//重新连接
const reconnect = () => {
    if (lockReconnect) {
        return;
    }
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    timeoutnum && clearTimeout(timeoutnum);
    timeoutnum = setTimeout(function () {
        //新连接
        webSocketToken && initWebSocket(webSocketToken);
        lockReconnect = false;
    }, 1000);
};

//重置心跳
const reset = () => {
    //清除时间
    clearTimeout(timeoutObj);
    clearTimeout(serverTimeoutObj);
    //重启心跳
    start();
};

const sendWebsocket = (e) => {
    // socket.send(`我发消息了`);
};

const webSocketOnError = (e) => {
    console.log("socket error:");
    console.log(e);

    // initWebSocket();
    reconnect();
};

//服务器返回的数据
const webSocketOnMessage = (e) => {
    // console.log("socket msg:");

    // console.log(e);

    //判断是否登录
    if (getToken()) {
        //window自定义事件[下面有说明]
        window.dispatchEvent(
            new CustomEvent("onmessageWS", {
                detail: {
                    data: JSON.parse(e.data),
                },
            })
        );
    }
    reset();
};

const closeWebsocket = (e) => {
    console.log("soket close");
    console.log(e);

    reconnect();
};

//断开连接
const close = () => {
    //WebSocket对象也有发送和关闭的两个方法，只需要在自定义方法中分别调用send()和close()即可实现。
    // console.log("断开连接：");

    socket && socket.close();
};
//具体问题具体分析,把需要用到的方法暴露出去
export default { initWebSocket, sendWebsocket, webSocketOnMessage, close };
